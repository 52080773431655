var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrapper"},[_c('div',{staticClass:"page-section section section-padding section-space--inner--60"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_vm._m(0),_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(" "+_vm._s(this.$route.params.kategori_produk)+" ")])])])]),_c('div',{staticClass:"row row-30 mb-40"},[_c('div',{staticClass:"col-xl-9 col-lg-8 col-md-8 col-12 order-md-2 order-lg-2",staticStyle:{"margin-bottom":"40px"}},[_c('div',{staticClass:"row justify-content-end",staticStyle:{"margin-bottom":"40px"}},[_c('div',{staticClass:"col-12 col-md-12"},[_c('h3',[_vm._v(_vm._s(this.$route.params.kategori_produk))])])]),_c('div',{staticClass:"row"},_vm._l((_vm.produkkategoris),function(produkkategori){return _c('div',{key:produkkategori.id_kategori,staticClass:"col-xl-2 col-md-4 col-6 col-category"},[_c('router-link',{attrs:{"to":/product/ +
                  produkkategori.kategori_produk +
                  ' ' +
                  produkkategori.tipe_produk +
                  /merk-produk/ +
                  produkkategori.merk_produk}},[_c('div',{staticClass:"category-card"},[_c('img',{attrs:{"src":produkkategori.url_gambar_kategori,"alt":produkkategori.merk_produk}}),_c('div',{staticClass:"category-card-title"},[_vm._v(" "+_vm._s(produkkategori.merk_produk)+" ")])])])],1)}),0)]),_c('div',{staticClass:"col-xl-3 col-md-4 col-lg-4 col-12 order-md-1 order-lg-1"},[_c('div',{staticClass:"sidebar sidebar-mobile"},[_c('h4',{staticClass:"sidebar-title"},[_vm._v("Kategori Produk")]),_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1 card-mobile",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-1",modifiers:{"accordion-1":true}}],attrs:{"block":""}},[_vm._v("Motor")])],1),_vm._l((_vm.listkategoris),function(listkategori){return _c('b-collapse',{key:listkategori.id_kategori,attrs:{"id":"accordion-1","accordion":"my-accordion","role":"tabpanel"}},[(listkategori.tipe_produk == 'Motor')?_c('div',[_c('b-card-body',{staticStyle:{"padding":"0.4rem 1.25rem"}},[_c('b-card-text',[_c('a',{attrs:{"href":/product-category/ +
                            listkategori.kategori_produk +
                            ' ' +
                            listkategori.tipe_produk}},[_vm._v(_vm._s(listkategori.kategori_produk)+" "+_vm._s(listkategori.tipe_produk))])])],1)],1):_vm._e()])})],2)],1),_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1 card-mobile",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-2",modifiers:{"accordion-2":true}}],attrs:{"block":""}},[_vm._v("Mobil")])],1),_vm._l((_vm.listkategoris),function(listkategori){return _c('b-collapse',{key:listkategori.id_kategori,attrs:{"id":"accordion-2","accordion":"my-accordion","role":"tabpanel"}},[(listkategori.tipe_produk == 'Mobil')?_c('div',[_c('b-card-body',{staticStyle:{"padding":"0.4rem 1.25rem"}},[_c('b-card-text',[_c('a',{attrs:{"href":/product-category/ +
                            listkategori.kategori_produk +
                            ' ' +
                            listkategori.tipe_produk}},[_vm._v(_vm._s(listkategori.kategori_produk)+" "+_vm._s(listkategori.tipe_produk))])])],1)],1):_vm._e()])})],2)],1),_c('div',{staticClass:"accordion",attrs:{"role":"tablist"}},[_c('b-card',{staticClass:"mb-1 card-mobile",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-0",attrs:{"header-tag":"header","role":"tab"}},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-3",modifiers:{"accordion-3":true}}],attrs:{"block":""}},[_vm._v("Power Tool")])],1),_vm._l((_vm.listkategoris),function(listkategori){return _c('b-collapse',{key:listkategori.id_kategori,attrs:{"id":"accordion-3","accordion":"my-accordion","role":"tabpanel"}},[(listkategori.kategori_produk == 'Power Tools')?_c('div',[_c('b-card-body',{staticStyle:{"padding":"0.4rem 1.25rem"}},[_c('b-card-text',[_c('a',{attrs:{"href":/product-category/ +
                            listkategori.kategori_produk +
                            ' ' +
                            listkategori.tipe_produk}},[_vm._v(_vm._s(listkategori.kategori_produk)+" "+_vm._s(listkategori.tipe_produk))])])],1)],1):_vm._e()])})],2)],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"breadcrumb-item"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Home >")])])}]

export { render, staticRenderFns }