<template>
  <div class="main-wrapper">
    <!-- Page Section Start -->
    <div class="page-section section section-padding section-space--inner--60">
      <div class="container">
        <div class="row">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">Home ></a></li>
              <li class="breadcrumb-item active" aria-current="page">
                {{ this.$route.params.kategori_produk }}
              </li>
            </ol>
          </nav>
        </div>
        <div class="row row-30 mb-40">
          <div
            class="col-xl-9 col-lg-8 col-md-8 col-12 order-md-2 order-lg-2"
            style="margin-bottom: 40px"
          >
            <div class="row justify-content-end" style="margin-bottom: 40px">
              <div class="col-12 col-md-12">
                <h3>{{ this.$route.params.kategori_produk }}</h3>
              </div>
            </div>
            <!-- SHOW DATA -->
            <!-- show all data -->
            <div class="row">
              <div
                v-for="produkkategori in produkkategoris"
                :key="produkkategori.id_kategori"
                class="col-xl-2 col-md-4 col-6 col-category"
              >
                <router-link
                  :to="
                    /product/ +
                    produkkategori.kategori_produk +
                    ' ' +
                    produkkategori.tipe_produk +
                    /merk-produk/ +
                    produkkategori.merk_produk
                  "
                >
                  <div class="category-card">
                    <img
                      :src="produkkategori.url_gambar_kategori"
                      :alt="produkkategori.merk_produk"
                    />
                    <div class="category-card-title">
                      {{ produkkategori.merk_produk }}
                    </div>
                  </div>
                </router-link>

                <!-- <div class="product-item">
                  <div class="product-inner category">
                    <div class="image">
                      <img
                        :src="produkkategori.url_gambar_kategori"
                        alt="Image Not Found"
                      />

                      <div class="image-overlay">
                        <div class="action-buttons">
                          <router-link
                            :to="
                              /product/ +
                              produkkategori.kategori_produk +
                              ' ' +
                              produkkategori.tipe_produk +
                              /merk-produk/ +
                              produkkategori.merk_produk
                            "
                          >
                            <a href="">
                              <div
                                v-if="produkkategori.merk_produk.length < 12"
                              >
                                <button>
                                  {{ produkkategori.merk_produk }}
                                </button>
                              </div>
                              <div v-else>
                                <button>
                                  {{
                                    produkkategori.merk_produk.substring(
                                      0,
                                      12
                                    ) + "..."
                                  }}
                                </button>
                              </div>
                            </a></router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>

     
          <div class="col-xl-3 col-md-4 col-lg-4 col-12 order-md-1 order-lg-1">
            <div class="sidebar sidebar-mobile">
              <h4 class="sidebar-title">Kategori Produk</h4>

             <!--  <div class="accordion" role="tablist">
                <b-card no-body class="mb-1 card-mobile">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.accordion-1>Aki</b-button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-1"
                    accordion="my-accordion"
                    role="tabpanel"
                    v-for="listkategori in listkategoris"
                    :key="listkategori.id_kategori"
                  >
                    <div v-if="listkategori.kategori_produk == 'Aki'">
                      <b-card-body>
                        <b-card-text
                          ><a
                            :href="
                              /product-category/ +
                              listkategori.kategori_produk +
                              ' ' +
                              listkategori.tipe_produk
                            "
                            >{{ listkategori.kategori_produk }}
                            {{ listkategori.tipe_produk }}</a
                          ></b-card-text
                        >
                      </b-card-body>
                    </div>
                  </b-collapse>
                </b-card>
              </div>

           
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1 card-mobile">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.accordion-2>Ban</b-button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                    v-for="listkategori in listkategoris"
                    :key="listkategori.id_kategori"
                  >
                    <div v-if="listkategori.kategori_produk == 'listkategori.kategori_produk'">
                      <b-card-body>
                        <b-card-text
                          ><a
                            :href="
                              /product-category/ +
                              listkategori.kategori_produk +
                              ' ' +
                              listkategori.tipe_produk
                            "
                            >{{ listkategori.kategori_produk }}
                            {{ listkategori.tipe_produk }}</a
                          ></b-card-text
                        >
                      </b-card-body>
                    </div>
                  </b-collapse>
                </b-card>
              </div>

          
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1 card-mobile">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.accordion-3>Lampu</b-button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                    v-for="listkategori in listkategoris"
                    :key="listkategori.id_kategori"
                  >
                    <div v-if="listkategori.kategori_produk == 'Lampu'">
                      <b-card-body>
                        <b-card-text
                          ><a
                            :href="
                              /product-category/ +
                              listkategori.kategori_produk +
                              ' ' +
                              listkategori.tipe_produk
                            "
                            >{{ listkategori.kategori_produk }}
                            {{ listkategori.tipe_produk }}</a
                          ></b-card-text
                        >
                      </b-card-body>
                    </div>
                  </b-collapse>
                </b-card>
              </div>

          
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1 card-mobile">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.accordion-4>Oli</b-button>
                  </b-card-header>
                  <b-collapse
                    id="accordion-4"
                    accordion="my-accordion"
                    role="tabpanel"
                    v-for="listkategori in listkategoris"
                    :key="listkategori.id_kategori"
                  >
                    <div v-if="listkategori.kategori_produk == 'Oli'">
                      <b-card-body>
                        <b-card-text
                          ><a
                            :href="
                              /product-category/ +
                              listkategori.kategori_produk +
                              ' ' +
                              listkategori.tipe_produk
                            "
                            >{{ listkategori.kategori_produk }}
                            {{ listkategori.tipe_produk }}</a
                          ></b-card-text
                        >
                      </b-card-body>
                    </div>
                  </b-collapse>
                </b-card>
              </div>

          
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1 card-mobile">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.accordion-5
                      >Power Tools</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="accordion-5"
                    accordion="my-accordion"
                    role="tabpanel"
                    v-for="listkategori in listkategoris"
                    :key="listkategori.id_kategori"
                  >
                    <div v-if="listkategori.kategori_produk == 'Power Tools'">
                      <b-card-body>
                        <b-card-text
                          ><a
                            :href="
                              /product-category/ +
                              listkategori.kategori_produk +
                              ' ' +
                              listkategori.tipe_produk
                            "
                            >{{ listkategori.kategori_produk }}
                            {{ listkategori.tipe_produk }}</a
                          ></b-card-text
                        >
                      </b-card-body>
                    </div>
                  </b-collapse>
                </b-card>
              </div>

          
              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1 card-mobile">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.accordion-6
                      >Shock Breaker</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="accordion-6"
                    accordion="my-accordion"
                    role="tabpanel"
                    v-for="listkategori in listkategoris"
                    :key="listkategori.id_kategori"
                  >
                    <div v-if="listkategori.kategori_produk == 'Shock Breaker'">
                      <b-card-body>
                        <b-card-text
                          ><a
                            :href="
                              /product-category/ +
                              listkategori.kategori_produk +
                              ' ' +
                              listkategori.tipe_produk
                            "
                            >{{ listkategori.kategori_produk }}
                            {{ listkategori.tipe_produk }}</a
                          ></b-card-text
                        >
                      </b-card-body>
                    </div>
                  </b-collapse>
                </b-card>
              </div> -->

              <div class="accordion" role="tablist">
                <b-card no-body class="mb-1 card-mobile">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.accordion-1
                      >Motor</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="accordion-1"
                    accordion="my-accordion"
                    role="tabpanel"
                    v-for="listkategori in listkategoris"
                    :key="listkategori.id_kategori"
                  >
                    <div v-if="listkategori.tipe_produk == 'Motor'">
                      <b-card-body style="padding: 0.4rem 1.25rem;">
                        <b-card-text
                          ><a
                            :href="
                              /product-category/ +
                              listkategori.kategori_produk +
                              ' ' +
                              listkategori.tipe_produk
                            "
                            >{{ listkategori.kategori_produk }}
                            {{ listkategori.tipe_produk }}</a
                          ></b-card-text
                        >
                      </b-card-body>
                    </div>
                  </b-collapse>
                </b-card>
              </div>
               <div class="accordion" role="tablist">
                <b-card no-body class="mb-1 card-mobile">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.accordion-2
                      >Mobil</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                    v-for="listkategori in listkategoris"
                    :key="listkategori.id_kategori"
                  >
                    <div v-if="listkategori.tipe_produk == 'Mobil'">
                      <b-card-body style="padding: 0.4rem 1.25rem;">
                        <b-card-text
                          ><a
                            :href="
                              /product-category/ +
                              listkategori.kategori_produk +
                              ' ' +
                              listkategori.tipe_produk
                            "
                            >{{ listkategori.kategori_produk }}
                            {{ listkategori.tipe_produk }}</a
                          ></b-card-text
                        >
                      </b-card-body>
                    </div>
                  </b-collapse>
                </b-card>
              </div>
               <div class="accordion" role="tablist">
                <b-card no-body class="mb-1 card-mobile">
                  <b-card-header header-tag="header" class="p-0" role="tab">
                    <b-button block v-b-toggle.accordion-3
                      >Power Tool</b-button
                    >
                  </b-card-header>
                  <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                    v-for="listkategori in listkategoris"
                    :key="listkategori.id_kategori"
                  >
                    <div v-if="listkategori.kategori_produk == 'Power Tools'">
                      <b-card-body style="padding: 0.4rem 1.25rem;">
                        <b-card-text
                          ><a
                            :href="
                              /product-category/ +
                              listkategori.kategori_produk +
                              ' ' +
                              listkategori.tipe_produk
                            "
                            >{{ listkategori.kategori_produk }}
                            {{ listkategori.tipe_produk }}</a
                          ></b-card-text
                        >
                      </b-card-body>
                    </div>
                  </b-collapse>
                </b-card>
              </div>

           
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Page Section End -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      produkkategoris: [],
      listkategoris: [],
      search: "",
      filter: "",
      showsearch: false,
      showfilter: false,
      caris: [],
    };
  },

  metaInfo() {
    return {
      title: "List Produk " + this.$route.params.kategori_produk,
      titleTemplate:
        "%s - CV. Putra Ramayana Grosir Ban, Oli, Aki, dan Sparepart Solo Terjangkau, Terlengkap, dan Terpercaya di Solo Jawa Tengah",
      meta: [
        {
          name: "keywords",
          content: "CV Putra Ramayana Solo",
        },
        {
          property: "og:keywords",
          content: "CV Putra Ramayana",
        },
        {
          property: "og:description",
          content:
            "Grosir Ban, Oli, Aki, dan Sparepart Solo Terlengkap dan Termurah - " +
            this.$route.params.kategori_produk,
        },
        {
          property: "og:title",
          content:
            this.$route.params.kategori_produk +
            " - CV Putra Ramayana " +
            "Grosir Ban Oli Aki Sparepart Solo Murah",
        },
        {
          property: "og:site_name",
          content: "www.putraramayanasolo.com" + this.$route.fullPath,
        },
        {
          property: "og:image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },
        { property: "og:type", content: "product" },
        {
          property: "og:viewport",
          content: "width=device-width, initial-scale=1",
        },

        // TWITTER META
        { name: "twitter:card", content: "website" },
        {
          name: "twitter:site",
          content: "www.putraramayanasolo.com" + this.$route.fullPath,
        },
        { name: "twitter:title", content: "Home - CV Putra Ramayana" },
        {
          name: "twitter:description",
          content:
            "CV Putra Ramayana Tempat Grosir Ban, Oli, Aki, dan Sparepart Terjangkau, Terlengkap, dan Terpercaya di Solo Jawa Tengah",
        },
        // Your twitter handle, if you have one.
        { name: "twitter:creator", content: "@cv_putraramayana" },
        {
          name: "twitter:image:src",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },

        // Google / Schema.org markup:
        { itemprop: "name", content: "Home - CV Putra Ramayana" },
        {
          itemprop: "description",
          content:
            "CV Putra Ramayana Tempat Grosir Ban, Oli, Aki, dan Sparepart Terjangkau, Terlengkap, dan Terpercaya di Solo Jawa Tengah",
        },
        {
          itemprop: "image",
          content:
            "https://firebasestorage.googleapis.com/v0/b/ramayana-product.appspot.com/o/Logo%2FLogo%20PR.jpg?alt=media&token=dca7045a-28c2-4b2e-8e23-219adf00c977",
        },

        { name: "robots", content: "index,follow" },
      ],
      htmlAttrs: {
        lang: "en",
        amp: true,
      },
    };
  },

  methods: {
    handleSearch() {
      this.$router.push("/search/" + this.search);
    },

    searchProduct() {
      axios
        .get(
          "https://admin.putraramayana.com/produks/getbynama/" + this.search
        )
        .then((response) => {
          this.caris = response.data;
          this.search = "";
          this.showsearch = true;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    getData() {
      axios
        .get(
          "https://admin.putraramayana.com/api/product-category/" +
            this.$route.params.kategori_produk
        )
        .then((response) => {
          this.produkkategoris = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },

    getListData() {
      axios
        .get("https://admin.putraramayana.com/api/list-kategori")
        .then((response) => {
          this.listkategoris = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
    },
  },
  created() {
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.getData();
      }
    );
  },

  mounted() {
    this.getData();
    this.getListData();
  },
};

</script>

