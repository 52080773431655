<template>
  <div class="home">
    <Header />
    <ProductCategory />
    <Footer />
    <OffCanvasMobileMenu />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import ProductCategory from "../components/ProductCategory.vue";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";

export default {
  name: "App",
  components: {
    Header,
    ProductCategory,
    Footer,
    OffCanvasMobileMenu,
  },
  metaInfo: {
    title: "CV.Putra Ramayana - Kategori Produk",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
};
</script>
